import { message } from 'antd'
import { AxiosRequestConfig } from 'axios'

import axiosInstance from './axios-instance'

export const RESPONSE_SUCCESS_MARKER = 0

export enum EErrorCode {
  // 成功
  SUCCESS = 0,

  // 系统错误
  SYSTEM_ERROR = -1000,

  // API不存在
  NO_API = -1001,

  // 登录失败
  LOGIN_FAILED = -1002,

  // 缺少参数
  MISSING_PARAMS = -1003,

  // 数据不存在
  DB_NOT_FOUND = -1004,

  // 未经授权
  UNAUTHORIZED = -1005,

  // 数据库错误
  DB_ERROR = -1006,

  // 邮箱已存在
  EMAIL_EXISTS = -2001,

  // 注册失败
  REGISTER_FAILED = -3001,

  // 推荐码不存在
  REFERRAL_CODE_NOT_FOUND = -3002,

  // 密码错误
  PASSWORD_ERROR = -3003,

  // 用户不存在
  USER_NOT_FOUND = -3004,

  // 验证失败
  VERIFICATION_FAILED = -3005,

  // 推荐码错误
  REFERRAL_CODE_FAILED = -3006,

  // 请求失败
  REQUEST_FAILED = -3007,

  // 数据已存在
  DATA_EXISTS = -3008,

  // 数据不存在
  DATA_NOT_FOUND = -3009,

  // 操作失败
  OPERATION_FAILED = -30010,

  // 操作限制
  OPERATION_LIMIT = -30011,

  // 上传失败
  UPLOAD_FAILED = -30012,

  // 解锁失败
  UNLOCK_FAILED = -30013,

  // 交易待支付
  TRANSACTION_WAIT_PAID = -30014,

  // 交易失败
  TRANSACTION_FAILED = -30015,

  // 墨水不足
  NOT_ENOUGH_INK = -30016,

  // 字数不足
  NOT_ENOUGH_WORD_COUNT = -30017,

  // 余额不足
  INSUFFICIENT_BALANCE = -30026
}

export const ERROR_MESSAGE_MAP = new Map<EErrorCode, string>([
  [EErrorCode.SYSTEM_ERROR, 'System error.'],
  [EErrorCode.NO_API, 'API not exist.'],
  [EErrorCode.LOGIN_FAILED, 'Wrong account number or password.'],
  [EErrorCode.MISSING_PARAMS, 'Missing parameters.'],
  [EErrorCode.DB_NOT_FOUND, 'Data not exist.'],
  [EErrorCode.UNAUTHORIZED, 'Login invalid.'],
  [EErrorCode.DB_ERROR, 'System error.'],
  [EErrorCode.EMAIL_EXISTS, 'Email exists.'],
  [EErrorCode.REGISTER_FAILED, 'Register failed.'],
  [EErrorCode.REFERRAL_CODE_NOT_FOUND, 'Referral code not found.'],
  [EErrorCode.PASSWORD_ERROR, 'password error.'],
  [
    EErrorCode.USER_NOT_FOUND,
    'Wrong account number, password or referral code.'
  ],
  [EErrorCode.VERIFICATION_FAILED, 'verification code incorrect.'],
  [EErrorCode.REFERRAL_CODE_FAILED, `You've already input a referral code.`],
  [EErrorCode.REQUEST_FAILED, 'Request failed.'],
  [EErrorCode.DATA_EXISTS, 'Data exists.'],
  [EErrorCode.DATA_NOT_FOUND, 'Data not found.'],
  [EErrorCode.OPERATION_FAILED, 'Operation failed.'],
  [EErrorCode.OPERATION_LIMIT, 'Operation Limit.'],
  [EErrorCode.UPLOAD_FAILED, 'Upload failed.'],
  [EErrorCode.UNLOCK_FAILED, 'Unlock failed.'],
  [EErrorCode.TRANSACTION_WAIT_PAID, 'Transaction wait to paid.'],
  [EErrorCode.TRANSACTION_FAILED, 'Transaction failed.'],
  [EErrorCode.NOT_ENOUGH_INK, 'Not enough ink.'],
  [EErrorCode.NOT_ENOUGH_WORD_COUNT, 'Not enough words'],
  [EErrorCode.INSUFFICIENT_BALANCE, 'Insufficient balance/bonus']
])

export interface ApiResult<T> {
  code: number
  message: string
  data: T
}

export async function get<T>(
  url: string,
  params?: unknown
): Promise<ApiResult<T>> {
  const response = await axiosInstance.get<ApiResult<T>>(url, { params })
  return response.data
}

export async function post<T>(
  url: string,
  data?: unknown,
  config?: AxiosRequestConfig<unknown>
): Promise<ApiResult<T>> {
  const response = await axiosInstance.post<ApiResult<T>>(url, data, config)
  return response.data
}

export async function put<T>(
  url: string,
  data?: unknown
): Promise<ApiResult<T>> {
  const response = await axiosInstance.put<ApiResult<T>>(url, data)
  return response.data
}

export async function del<T>(
  url: string,
  params?: unknown
): Promise<ApiResult<T>> {
  const response = await axiosInstance.delete<ApiResult<T>>(url, { params })
  return response.data
}

export function isSuccess<T>(resp?: ApiResult<T>) {
  if (!resp) {
    return false
  }

  const errorMessage = ERROR_MESSAGE_MAP.get(resp.code)

  if (errorMessage) {
    message.error(errorMessage)
    return false
  }

  if (resp.code !== RESPONSE_SUCCESS_MARKER) {
    message.error(resp.message)
    return false
  }

  return resp.code === RESPONSE_SUCCESS_MARKER
}
