import { message, Upload } from 'antd'
import type { UploadProps, UploadFile } from 'antd'
import type { RcFile, UploadChangeParam } from 'antd/es/upload/interface'

import { ApiResult, isSuccess } from '@/httpRequest'
import { TUploadResp } from '@/httpRequest/common/upload'
import { getGlobalConfig } from '@/utils'

import styles from '../index.module.less'

/**
 * 文件类型
 */
export enum EFileType {
  /**
   * 其他类型
   */
  Other = 0,

  /**
   * 头像类型
   */
  Avatar = 1,

  /**
   * 封面类型
   */
  Cover = 2,

  /**
   * 插图类型
   */
  Illustration = 3,

  /**
   * 视频类型
   */
  Video = 4,

  Report = 5,

  Reel = 6
}

interface UploadImgProps {
  fileUrl?: string
  disabled?: boolean
  mode?: number
  className?: string
  title?: string
  subtitle?: string
  onChange?: (url: string) => void
  type?: EFileType
}

const baseURL = getGlobalConfig().VITE_GLOB_API_URL

const beforeUpload = (file: RcFile) => {
  if (file.size > 2 * 1024 * 1024) {
    message.error('Please select an image smaller than 2M')
    return Upload.LIST_IGNORE
  }

  return true
}

const UploadImg = ({
  disabled,
  className = '',
  title = 'Cover picture',
  mode = 1,
  subtitle = '600px * 420px',
  onChange,
  fileUrl,
  type = EFileType.Other
}: UploadImgProps) => {
  // 获取token
  const token = localStorage.getItem('token')
  const props: UploadProps = {
    name: 'file',
    action: `${baseURL}/common/upload`,
    accept: 'image/*',
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      type
    },
    onChange: async (
      info: UploadChangeParam<UploadFile<ApiResult<TUploadResp>>>
    ) => {
      const resp = info.file.response
      if (resp && isSuccess(resp)) {
        typeof onChange === 'function' && onChange(resp.data.url)
      }
    },
    // 限制上传1个
    maxCount: 1,
    beforeUpload
  }

  return (
    <div
      className={[
        'flx-align-center mb20 relative',
        styles.uploadFile,
        mode === 2 ? styles.uploadFileHorizontal : '',
        className
      ]
        .join(' ')
        .trim()}
    >
      <div className="upload-file-wrap">{fileUrl && <img src={fileUrl} />}</div>
      <div className="upload-file-info">
        <h4>{title}</h4>
        <p>{subtitle}</p>
        {!disabled && (
          <Upload {...props} className={styles.uploadFileProgress}>
            <button className="g-btn g-btn-primary">Upload</button>
          </Upload>
        )}
      </div>
    </div>
  )
}
export default UploadImg
