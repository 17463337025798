import React, { LazyExoticComponent, lazy } from 'react'

import { Navigate, RouteObject, useParams } from 'react-router-dom'

import Loading from '@/components/Loading'

export const LazyImportComponent = (props: {
  lazyChildren: LazyExoticComponent<() => JSX.Element>
}) => {
  return (
    <React.Suspense fallback={<Loading />}>
      <props.lazyChildren />
    </React.Suspense>
  )
}

const NovelRedirect = () => {
  const { resourceInfo } = useParams()
  return <Navigate to={`/cover/${resourceInfo}`} replace />
}

const ChapterRedirect = () => {
  const { resourceInfo } = useParams()
  return <Navigate to={`/detail/novel/${resourceInfo}`} replace />
}

const StoryRedirect = () => {
  const { tags } = useParams()
  return <Navigate to={`/home/story/${tags || ''}`} replace />
}

const routes: RouteObject[] = [
  {
    path: '/',
    element: <Navigate to={`/home`} replace />
  },
  {
    path: '/story/:tag?',
    element: <StoryRedirect />
  },
  {
    path: '/home',
    element: (
      <LazyImportComponent
        lazyChildren={lazy(() => import('@/pages/Home/components/Discover'))}
      />
    )
  },
  {
    path: '/home/story/:tag?',
    element: (
      <LazyImportComponent
        lazyChildren={lazy(() => import('@/pages/Home/components/Story'))}
      />
    )
  },
  {
    path: '/community/:tag?',
    element: (
      <LazyImportComponent
        lazyChildren={lazy(() => import('@/pages/Home/components/Community'))}
      />
    )
  },
  {
    path: '/storyList',
    element: (
      <LazyImportComponent
        lazyChildren={lazy(() => import('@/pages/Home/components/StoryList'))}
      />
    )
  },
  {
    path: '/following',
    element: (
      <LazyImportComponent
        lazyChildren={lazy(() => import('@/pages/Home/components/Following'))}
      />
    )
  },
  {
    path: '/library',
    element: (
      <LazyImportComponent
        lazyChildren={lazy(() => import('@/pages/Home/components/Library'))}
      />
    )
  },
  {
    path: 'searchResult',
    element: (
      <LazyImportComponent
        lazyChildren={lazy(
          () => import('@/pages/Home/components/SearchResult')
        )}
      />
    )
  },
  {
    path: '/login',
    element: (
      <LazyImportComponent lazyChildren={lazy(() => import('@/pages/Login'))} />
    )
  },
  {
    path: '/forget',
    element: (
      <LazyImportComponent
        lazyChildren={lazy(() => import('@/pages/Login/Forget'))}
      />
    )
  },
  {
    path: '/login/email',
    element: (
      <LazyImportComponent
        lazyChildren={lazy(() => import('@/pages/Login/Email'))}
      />
    )
  },
  {
    path: '/register',
    element: (
      <LazyImportComponent lazyChildren={lazy(() => import('@/pages/Login'))} />
    )
  },
  {
    path: '/register/email',
    element: (
      <LazyImportComponent
        lazyChildren={lazy(() => import('@/pages/Login/Register'))}
      />
    )
  },
  // 第三方注册
  {
    path: '/register/third',
    element: (
      <LazyImportComponent
        lazyChildren={lazy(() => import('@/pages/Login/Register'))}
      />
    )
  },
  // Publish Story
  {
    path: '/publish',
    element: (
      <LazyImportComponent
        lazyChildren={lazy(() => import('@/pages/Publish/Story'))}
      />
    )
  },
  // Publish Book List
  {
    path: '/publish/book',
    element: (
      <LazyImportComponent
        lazyChildren={lazy(() => import('@/pages/Publish/BookList'))}
      />
    )
  },
  // Publish Discuss
  {
    path: '/publish/discuss',
    element: (
      <LazyImportComponent
        lazyChildren={lazy(() => import('@/pages/Publish/Discuss'))}
      />
    )
  },
  {
    path: '/cover/:resourceInfo',
    element: (
      <LazyImportComponent
        lazyChildren={lazy(() => import('@/pages/Detail'))}
      />
    )
  },
  {
    path: '/novel/:resourceInfo',
    element: <NovelRedirect />
  },
  {
    path: '/chapter/:resourceInfo',
    element: <ChapterRedirect />
  },
  {
    path: '/detail/book/:resourceInfo',
    element: (
      <LazyImportComponent
        lazyChildren={lazy(() => import('@/pages/Detail/Book'))}
      />
    )
  },
  {
    path: '/detail/discuss/:resourceInfo',
    element: (
      <LazyImportComponent
        lazyChildren={lazy(() => import('@/pages/Detail/Discuss'))}
      />
    )
  },
  {
    path: '/detail/novel/:resourceInfo',
    element: (
      <LazyImportComponent
        lazyChildren={lazy(() => import('@/pages/Detail/Novel'))}
      />
    )
  },
  {
    path: '/detail/video/:resourceInfo',
    element: (
      <LazyImportComponent
        lazyChildren={lazy(() => import('@/pages/Detail/Video'))}
      />
    )
  },
  {
    path: '/about',
    element: (
      <LazyImportComponent lazyChildren={lazy(() => import('@/pages/About'))} />
    )
  },
  {
    path: '/communityguidelines',
    element: (
      <LazyImportComponent lazyChildren={lazy(() => import('@/pages/Terms'))} />
    )
  },
  {
    path: '/features',
    element: (
      <LazyImportComponent
        lazyChildren={lazy(() => import('@/pages/Policy'))}
      />
    )
  },
  {
    path: '/profile',
    element: <Navigate to="/personal/information" replace />
  },
  {
    path: '/personal',
    element: (
      <LazyImportComponent
        lazyChildren={lazy(() => import('@/pages/Personal'))}
      />
    ),
    children: [
      {
        path: 'information',
        element: (
          <LazyImportComponent
            lazyChildren={lazy(
              () => import('@/pages/Personal/information/Information')
            )}
          />
        )
      },
      {
        path: 'collection',
        element: (
          <LazyImportComponent
            lazyChildren={lazy(() => import('@/pages/Personal/Collection'))}
          />
        )
      },
      {
        path: 'liked',
        element: (
          <LazyImportComponent
            lazyChildren={lazy(() => import('@/pages/Personal/Liked'))}
          />
        )
      },
      {
        path: 'history',
        element: (
          <LazyImportComponent
            lazyChildren={lazy(() => import('@/pages/Personal/History'))}
          />
        )
      },
      {
        path: 'publish',
        element: (
          <LazyImportComponent
            lazyChildren={lazy(() => import('@/pages/Personal/MyPublish'))}
          />
        )
      },
      {
        path: 'review',
        element: (
          <LazyImportComponent
            lazyChildren={lazy(() => import('@/pages/Personal/UnderReview'))}
          />
        )
      },
      {
        path: 'followers',
        element: (
          <LazyImportComponent
            lazyChildren={lazy(() => import('@/pages/Personal/Followers'))}
          />
        )
      },
      {
        path: 'followings',
        element: (
          <LazyImportComponent
            lazyChildren={lazy(() => import('@/pages/Personal/Followings'))}
          />
        )
      }
    ]
  },
  {
    path: '/people/:id',
    element: (
      <LazyImportComponent
        lazyChildren={lazy(() => import('@/pages/People'))}
      />
    ),
    children: [
      {
        path: 'collection',
        element: (
          <LazyImportComponent
            lazyChildren={lazy(() => import('@/pages/People/Collection'))}
          />
        )
      },
      {
        path: 'liked',
        element: (
          <LazyImportComponent
            lazyChildren={lazy(() => import('@/pages/People/Liked'))}
          />
        )
      },
      {
        path: 'publish',
        element: (
          <LazyImportComponent
            lazyChildren={lazy(() => import('@/pages/People/MyPublish'))}
          />
        )
      }
    ]
  },
  {
    path: '/modalexample',
    element: (
      <LazyImportComponent
        lazyChildren={lazy(() => import('@/pages/Modal/index'))}
      />
    )
  },
  {
    path: '/loading',
    element: (
      <LazyImportComponent
        lazyChildren={lazy(() => import('@/pages/Loading/index'))}
      />
    )
  },
  {
    path: '/403',
    element: (
      <LazyImportComponent
        lazyChildren={lazy(() => import('@/pages/Error/403'))}
      />
    )
  },
  {
    path: '/404',
    element: (
      <LazyImportComponent
        lazyChildren={lazy(() => import('@/pages/Error/404'))}
      />
    )
  },
  {
    path: '500',
    element: (
      <LazyImportComponent
        lazyChildren={lazy(() => import('@/pages/Error/500'))}
      />
    )
  },
  {
    path: '*',
    element: (
      <LazyImportComponent
        lazyChildren={lazy(() => import('@/pages/Error/404'))}
      />
    )
  }
]

export default routes
